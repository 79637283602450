import { graphql } from "gatsby";
import gql from "graphql-tag";
import React from "react";

import { previewFragments } from "@blocks/fragments";
import BlocksRenderer from "@components/BlocksRenderer";
import Footer from "@components/Footer";
import getPostData from "@utils/getPostData";
import useUIColorCheck from "@utils/useUIColorCheck";

const PageTemplate = props => {
  const { data, preview, transitionStatus } = props;
  const page = getPostData("page", { data, preview });

  useUIColorCheck([transitionStatus === "entered"]);

  return (
    <>
      <div className="page">
        <BlocksRenderer
          blocks={page.blocks}
          entered={transitionStatus === "entered"}
        />
      </div>

      <Footer />
    </>
  );
};

export const query = graphql`
  query($wpId: Int!) {
    wpgraphql {
      page: pageBy(pageId: $wpId) {
        uri
        title
        status
        slug
        content

        blocks {
          ...Roll_Block
        }
      }
    }
  }
`;

export const previewQuery = gql`
  query($wpId: Int!) {
    wpgraphql {
      page: pageBy(pageId: $wpId) {
        blocks {
          ...Roll_Block
        }
        blocksPreview {
          ...Roll_Block
        }

        preview: revisions(first: 1, before: null) {
          nodes {
            uri
            title
            status
            slug
            content
          }
        }
      }
    }
  }

  ${previewFragments}
`;

export default PageTemplate;
